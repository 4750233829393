<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="navMenuItems" />
  </div>
</template>

<script>
import items from '@/navigation/vertical'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  setup() {
    return {
    }
  },
  computed: {
    currentModule() {
      return this.$store.getters['auth/currentModule']
    },
    navMenuItems() {
      let menus = []
      if (this.currentModule) {
        menus = items[`${this.currentModule}`]
      }
      return menus
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
