<template>
  <b-container>
    <router-view />
  </b-container>
</template>

<script>
import AppFooter from '@core/layouts/components/AppFooter.vue'
import { BNavbar, BContainer } from 'bootstrap-vue'
import Navbar from '../components/Navbar.vue'

export default {
  name: 'CustomHorizontal',
  components: {
    Navbar,
    AppFooter,
    BContainer,
    BNavbar,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
