<template>
  <ul
    id="main-menu-navigation"
    class="nav navbar-nav d-flex align-items-center"
  >
    <b-avatar
            class="mr-1"
            size="40"
            variant="light-primary"
            @click.native="$router.push('/')"
    >
      <feather-icon icon="ArrowLeftCircleIcon" />
    </b-avatar>
    <component
      :is="resolveNavComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />

  </ul>
</template>

<script>
import HorizontalNavMenuHeaderLink from '../horizontal-nav-menu-header-link/HorizontalNavMenuHeaderLink.vue'
import HorizontalNavMenuHeaderGroup from '../horizontal-nav-menu-header-group/HorizontalNavMenuHeaderGroup.vue'
import {
 BAvatar,
} from 'bootstrap-vue'
export default {
  components: {
    HorizontalNavMenuHeaderLink,
    HorizontalNavMenuHeaderGroup,
BAvatar,  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const resolveNavComponent = item => {
      if (item.children) return 'horizontal-nav-menu-header-group'
      return 'horizontal-nav-menu-header-link'
    }

    return {
      resolveNavComponent,
    }
  },
}
</script>
horizontal-layout horizontal-menu navbar-floating footer-static

horizontal-layout vertical-overlay-menu menu-hide navbar-floating footer-static