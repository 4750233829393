<template>
  <router-view v-if="isVertical" />
  <custom-horizontal v-else />
</template>

<script>
import HorizontalLayout from '@/layouts/horizontal/LayoutHorizontal'
import VerticalLayout from '@/layouts/vertical/LayoutVertical'
import CustomHorizontal from '@/layouts/horizontal/CustomHorizontal'
import { $themeConfig } from '/themeConfig'

export default {
  components: {
    HorizontalLayout,
    VerticalLayout,
    CustomHorizontal,
  },
  computed: {
    isVertical() {
      return $themeConfig.layout.type === 'vertical'
    },
  },
}
</script>
