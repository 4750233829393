<template>
<!--<div>-->
<!--  <b-navbar >-->
<!--    <navbar />-->
<!--  </b-navbar>-->
  <layout-horizontal>
    <!--  <div style="background-image: url('/bg.jpg'); object-fit: cover;width: 100vw;height: 100vh;">-->
    <b-container class="pt-2">
      <router-view />
      <!-- Footer -->
      <!--    <footer-->
      <!--      class="footer footer-light"-->
      <!--    >-->
      <!--      <slot name="footer">-->
      <!--        <app-footer />-->
      <!--      </slot>-->
      <!--    </footer>-->
      <!-- /Footer -->
    </b-container>
  </layout-horizontal>
<!--</div>-->
</template>

<script>
import AppFooter from '@core/layouts/components/AppFooter.vue'
import LayoutHorizontal from '@core/layouts/layout-horizontal/LayoutHorizontal'
import { BNavbar, BContainer } from 'bootstrap-vue'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    AppFooter,
    BContainer,
    Navbar,
    BNavbar,
    LayoutHorizontal,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
<style lang="scss">
    footer.footer {
         padding: 0.8rem 0.8rem;
    }
    /*.navbar-floating .header-navbar-shadow {*/
    /*  background: transparent !important;*/
    /*  left: 0;*/
    /*}*/
    /*.header-navbar.navbar-shadow {*/
    /*   box-shadow: none !important;*/
    /*}*/
    /*.header-navbar.floating-nav {*/
    /*   position:absolute !important;*/
    /*   width: 100% !important;*/
    /*  margin: 0 !important;*/
    /*  ;*/
    /*  z-index: 12;*/
    /*}*/
</style>
